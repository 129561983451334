@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --primary: 117, 45%, 52%;
    --primary-foreground: 0, 0%, 100%;

    --secondary: 0, 0%, 0%;
    --secondary-foreground: 0, 0%, 100%;

    --tooltip: 100, 4%, 16%;
    --scrollbar-bg: 120, 0%, 35%;
    
    --lunar: 120, 4%, 24%;

    --gray: 0, 0%, 36%;
    --gray-transparent70-solid: 0, 0%, 30%;
    /* in components that are overlaying something, the transparent HSLA values are not appliable correctly
       so this i.e representes hsla(0, 0%, 0%, 0.3) without the alpha (transparency) value */
    --black-transparent30-solid: 0, 0%, 22%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
    --white: 0, 0%, 100%;

    --success: 117, 45%, 52%;
    --danger: 36, 89%, 53%;
    --error: 356, 65%, 49%
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --scrollbar-bg: 120, 0%, 35%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: hsla(var(--scrollbar-bg));
  backdrop-filter: blur(8px);
}

::-webkit-scrollbar-thumb:hover {
  background: hsla(var(--scrollbar-bg), 0.24);
}
